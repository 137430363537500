<template>
    <section>
        <div class="exceptions-header">
            <div>Selecione dias específicos para modificar sua disponibilidade.</div>
            <b-button 
                variant="outline-secondary" 
                @click="addException"
            >
                Nova exceção
            </b-button>
        </div>
        <div v-if="!exceptions?.length" class="empty">
            <div>Nenhuma exceção por aqui ainda</div>
        </div>
        <ScheduleExceptionForm 
            v-for="(exception, i) in exceptions" 
            :key="i" 
            :exception="exception" 
            :hideDuration="hideDuration"
            @removeException="removeException(i)"
        />
    </section>
</template>
<script>
import api from '../api'

export default {
    components: {
        ScheduleExceptionForm: () => import('@/modules/scheduleConfig/components/ScheduleExceptionForm'),
    },
    props: {
        scheduleConfigId: String,
        exceptions: [],
        hideDuration: { type: Boolean, default: false }
    },
    data() {
        return {
            canEdit: true,
            interval: {},
            langDatePicker: {
                formatLocale: {
                    weekdaysMin: ['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sáb']
                }
            },
        }
    },
    methods: {
        addException() {
            this.exceptions.push({
                schedule_config_id: this.scheduleConfigId,
                date_start: '',
                date_end: '',
                intervals: [{}]
            })
        },
        removeException(index) {
            this.$swal({
                icon: 'question',
                html: `<div class="body-alert">Deseja realmente excluir esta <span>exceção</span>?</div>`,
                showCancelButton: true,
                confirmButtonColor: '#F63220',
                confirmButtonText: 'Confirmar',
                cancelButtonText: 'Cancelar',
                reverseButtons: true
            }).then(res => {
                if (res.isConfirmed) {
                    if (this.exceptions[index].id) {
                        api.deleteScheduleException(this.exceptions[index].id)
                        .then(() => {
                            this.$toast.success('Exceção excluída com sucesso')
                        })
                    }
                    this.exceptions.splice(index, 1)
                }
            })
        },
    }
}
</script>

<style lang="scss" scoped>
.exceptions-header {
    width: 100%;
    display: inline-flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 2rem;
}
.empty {
    width: 100%;
    height: 400px;
    display: flex;
    gap: 1rem;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.hr {
    width: 100%;
}
</style>